import React, { useState, useContext, useEffect } from 'react';
import {makeStyles} from '@material-ui/core'
import {DataContext} from '../../dataContext'
import SimpleDialog from './SimpleDialog';
import CheckCircle from '@material-ui/icons/CheckCircle';

const styles = makeStyles({
    resultsRoot: {
        display: 'flex', 
        padding: '0px 5px',
        display: 'flex', 
        flexDirection: 'column'
    }, 
    title: {
        textTransform: 'uppercase',
        paddingLeft: 5,
        paddingBottom: 10
    },
    candidateContainer: {
        display: 'flex', 
        flexDirection: 'column'

    },
    candidateRoot: {
        position: 'relative',
        display: 'flex', 
        flexDirection: 'row',
        // height: 120,
        padding: '10px 0', 
        cursor: 'pointer',
    },
    resultsInfo: {
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
        '& #pollsReported': {
            fontSize: 12,
            fontWeight: 'bolder'
        },
        '& #totalVotes': {
            fontSize: 10,
            color: 'darkslategrey'
        }
    },
    imageDiv: {
        height: props=>props.screensize < 700 ? 100 : 120,
        position: 'relative',
        marginBottom: 25,
        minWidth: 50,
        width: props=>props.screensize < 700 ? 50 : 70,
        borderRadius: '8px',
        overflow: 'hidden',
        '& #partyCode': {
            position: 'absolute', 
            bottom: 8,
            color: 'white',
            width: '100%',
            textAlign: 'center'
        },
        '& img': {
            width: '100%',
            transition: 'width 500ms'
        }
    },
    candidateLeftDiv: {
        flex: 3, 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'space-between',

        '& #candidateRiding': {
            flex: 1,
            fontSize: 24,
            fontWeight: 'lighter'
        }
    },
    candidateName: {
        flex: 3, 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center',
        '& #candidateName': {
            fontWeight: 'bolder',
            fontSize: 22,
        },
        '& #candidateParty': {
            fontSize: 24, 
            fontWeight: 'bold', 
        }
    }, 
    candidateRightDiv: {
        flex: 2, 
        justifySelf: 'flex-end',
        display: 'flex', 
        flexDirection: 'column',
        paddingLeft: '10px',
        justifyContent: 'space-between',
        '& #pollsDiv': {
            textAlign: 'right'
        },
        '& #candidateElected': {
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'flex-end',
            fontSize: props=>props.screensize < 700 ? 12 : 14,
        }
    },
    votesDiv: {
        textAlign: 'right',
        display: 'flex', 
        justifyContent: 'center',
        flexDirection: 'column',
        flexGrow: 1,
        '& #votesLabel': {
            fontSize: props=>props.screensize < 700 ? 16 : 22,
            fontWeight: 'bolder'
        },
        '& #votesTotal': {
            fontSize: props=>props.screensize < 700 ? 12 : 14,
            fontWeight: 'lighter'
        }
    }
})

function ResultsBox({results, filter, handleSelectRiding}) {
    const classes = styles()
    const [ridingResults, setRidingResults] = useState('')
    const [show, toggleShow] = useState(false)
    const {data} = useContext(DataContext)

    const getColor = (candidate) => {
        const partyCode = candidate.partyCode
        let partyInfo = data.party.partyResults.find(party => {
            return party.nameShort === partyCode
        })
        if (partyInfo) {
            return partyInfo
        } else 
        return {
            color: "#C0C0C0",
            name: "Other",
            nameShort: "OTH"
        }
    }

    const getRidingResults = (ridingName) => {
        let riding =  data.full.data.find(el=>el.name === ridingName)
        console.log(riding)
        handleSelectRiding(riding);
        toggleShow(true)
    }

    return (
        <div className={classes.resultsRoot}>
            <div className={classes.title}>{filter}</div>
            <div className={classes.candidateContainer}>
            {filter && results.data.length ? results.data.map(candidate=>{
                const partyData = getColor(candidate)
                return (<div id="filteredCandidate" className={classes.candidateRoot} onClick={()=>getRidingResults(candidate.riding)}>
                    <div className={classes.candidateLeftDiv}>
                        <div id="candidateRiding">                            
                            {candidate.riding}
                        </div>
                        <div className={classes.candidateName}>
                            <div id="candidateName">{candidate.firstName + ' ' + candidate.lastName}</div>
                            <div id="candidateParty" style={{ color: partyData.color}}>{candidate.partyCode}</div>
                         </div>
                        
                    </div>
                    <div className={classes.candidateRightDiv}>
                        <div id='pollsDiv'>
                                <div>{candidate.pollsReported}/{candidate.pollsTotal} polls reporting</div>
                        </div>
                        <div className={classes.votesDiv}>
                            <div>
                                <div id='votesLabel'>Leading By: </div>
                                <div>{candidate.lead} votes</div>
                                <div>{candidate.leadPercent}%</div>
                            </div>
                            
                        </div>
                        {candidate.elected ? <div id="candidateElected">
                            <CheckCircle style={{paddingRight: 5, fontSize: 14, color: 'green'}} /> Elected
                        </div>
                        :
                        <div style={{fontSize: 14}}/>
                        }
                    </div>
                </div>)
            }) : <div style={{paddingLeft: 5}}> No results available</div>}
            </div>
            {/* {ridingResults && <SimpleDialog getColor={getColor} results={ridingResults} open={show} handleClose={()=>toggleShow(false)}/>} */}
        </div>
    )
}

export default ResultsBox
