import React, { useContext, Suspense, useState, useEffect } from 'react'
import {
    useParams
} from 'react-router-dom'
import {makeStyles} from '@material-ui/core'

import MapContextProvider, { MapContext } from './mapContext';

import Map from './Map'
import Declaration from './Declaration';
import Sidebar from './Sidebar'
import { DataContext } from '../../dataContext';


const styles = makeStyles({
    mapAppContainer: {
        display: 'flex',
        position: 'relative', 
        height: '100%'
    },
    mapDiv: {
        display: 'flex', 
        flex: 5,
        minHeight: 200,
        flexGrow: 4,
        position: 'relative',
    }, 
    sidebarDiv: {
        backgroundColor: 'lightgrey',
        position: 'relative', 
        height: '100%', 
        flex: 4,
        flexGrow: 3,
        boxShadow: '2px 2px 15px 0px black',
        zIndex: 401,
        overflow: 'hidden'
    }
})

export default function MapApp({showBanner, isIA}) {
    const [selectedRiding, setSelected] = useState('')
    const [isMobile, setIsMobile] = useState(false)
    
    const {data} = useContext(DataContext)
    const classes = styles();

    const {riding} = useParams()

    useEffect(()=> {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setIsMobile(true)
        }
    },[])
    
  

    return (
        <div id="mapAppContainer" className={classes.mapAppContainer}>
            {(data.full) && 
            <MapContextProvider>
                 {!((isMobile && riding) || (riding && isIA)) && 
                 
                 <div className={classes.mapDiv}>
                   <Map showBanner={showBanner} isMobile={isMobile}/>
                </div>}
                <div id="sideBarDiv" className={classes.sidebarDiv}>
                    {(isMobile && riding) && <Declaration />}
                    <Sidebar selectedRiding={selectedRiding}/>
                </div>
            </MapContextProvider>
        }
        </div>
    )
}