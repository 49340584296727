import React, {useContext, useState, useEffect, useRef} from 'react'
import { DataContext } from '../../dataContext'
import {makeStyles} from '@material-ui/core'

const styles = makeStyles({
    declarationHolder: {
        width: '100%',
        fontFamily: 'proxima-nova-semibold',
        zIndex: 100,
    },
    declarationDiv: {
        
        color: 'white', 
        textShadow: '1px 1px black', 
        fontWeight: 600,
        display: 'flex', 
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center'
    }
})
export default function Declaration() {
    const {data} = useContext(DataContext)
    const [declaration, setDeclaration] = useState('')
    const classes = styles()
    
    useEffect(()=> {
        if (data.declaration.overallResult.partyName) {
            const text = data.declaration.overallResult.partyName + " " + data.declaration.overallResult.resultText;
            let color;
            let party = data.party.partyResults.filter(party=>{
                return party.nameShort === data.declaration.overallResult.partyNameShort
            })[0]
            if (party) {
                color = party.color
            } else {
                color = '#C0C0C0'
            }
            setDeclaration({text, color})
        } else {
            setDeclaration('')
        }
    }, [data])

    return (
        <div className={classes.declarationHolder}>
            {console.log(declaration === true)}
            {declaration && <div className={classes.declarationDiv} style={{background: declaration.color}}>{declaration.text}</div>}
        </div>
    )
}
