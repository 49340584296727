import React, {useEffect, useState, useContext} from 'react'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';

import Results from './Results'
import { DataContext } from '../../dataContext';

const styles = makeStyles({
    accordionBody: {
        display: 'flex', 
        flexDirection: 'column',
        width: '100%'
    },
    resultsSummary: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        paddingBottom: 10,
        '& #polls': {
            fontSize: 13,
            fontWeight: 600
        },
        '& #votes': {
            fontSize: 13,
            fontWeight: 600
        }
    }
})

const AccordionBody = (props) => {
    const classes = styles()

    const [results, setResults] = useState(null)

    const {data} = useContext(DataContext)


    const getResults =  () => {
        let contest =  data.full.data.filter(contest => contest.name === props.riding)[0]
        setResults(contest)
   }

    useEffect(() => {
        getResults();
        return () => {
            setResults(null)
        }
    }, [])
    
    useEffect(() => {
        getResults();
        return () => {
            setResults(null)
        }
    }, [data])

    return (
        <AccordionDetails key={props.id} >
        {results && 
            <div className={classes.accordionBody}>
            <div className={classes.resultsSummary}>
                <div id="polls">{results.pollsReported}/{results.pollsTotal} polls reporting</div>
                <div id="votes">{results.votes.toLocaleString('en')} total votes</div>
            </div>
            <Results results={results} id={props.id}/>
            </div>

        }
        </AccordionDetails>
    )
}

export default AccordionBody