import React, {useContext, useState, useEffect, useRef} from 'react'
import {makeStyles} from '@material-ui/core'
import {useParams} from 'react-router-dom'

import {MapContainer, GeoJSON, TileLayer, Tooltip, LayerGroup, Popup} from 'react-leaflet'

import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import { geoCentroid, geoBounds, geoPath, geoArea } from "d3-geo";

import { MapContext } from './mapContext';
import { DataContext } from '../../dataContext'

import MyTooltip from './Tooltip'
import Declaration from './Declaration'

const styles = makeStyles({
    mapRoot: {
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column',
        position: 'relative',
        // maxHeight: '90%'
    },
    mapHolder: {
        flexGrow: 1,
        position: 'relative', 
        
    },
    mobilePopup: {
        backgroundColor: 'white', 
        position: 'absolute', 
        top: 25, 
        right: 10, 
        zIndex: 100, 
        padding: 8, 
        borderRadius: 10,
        boxShadow: '-1px 1px 5px #424242'

    },
    declarationDiv: {
        position: 'absolute', 
        width: '100%',
        zIndex: 100,
        top: 0,
        padding: '5px 0px',
        color: 'white', 
        textShadow: '1px 1px black', 
        fontWeight: 600,
        textAlign: 'center'
    },
    resetButton: {
        display: 'flex',
        boxShadow: '0 1px 4px rgba(0,0,0,0.65)',
        height: 30,
        width: 30,
        fontSize: '1.7rem !important',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        background: '#FFFFFF',
        cursor: 'pointer',  
        color: 'black'  
    },  
    loading: {
        position: 'absolute', 
        width: '100%',
        height: '100%',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        zIndex: 500
    }
})

const defaultStyle={
    weight: 0.6,
    fillOpacity: 0.7,
    color: 'black',
}

const selectedStyle = {
    weight: 0.8,
    fillOpacity: 0.8
}

// const jsonfile = require()

const Map = ({showBanner, isMobile}) => {
    const classes = styles()
    const {state, dispatch, geoJSON} = useContext(MapContext)
    const {data} = useContext(DataContext)
    const [declaration, setDeclaration] = useState(null)
    const [popupResults, setPopupResults] = useState({
        results: null, 
        color: null, 
        riding: null
    })
    const [showPopup,togglePopup] = useState(false)

    let counter = Date.now()

    const geoRef = useRef(null)
    const selectedRef = useRef(null)
    const tooltipRef = useRef(null)

    useEffect(()=> {
        if (data.declaration.overallResult.partyName) {
            const text = data.declaration.overallResult.partyName + " " + data.declaration.overallResult.resultText;
            let color;
            let party = data.party.partyResults.filter(party=>{
                return party.nameShort === data.declaration.overallResult.partyNameShort
            })[0]
            if (party) {
                color = party.color
            } else {
                color = '#C0C0C0'
            }

            setDeclaration({text, color})
        }

    }, [data])

    useEffect(() => {
        if (state.map) {
            if (state.selectedProvince && state.provinceZoom) {
                let prov = state.provinceData.prov.filter(province=> province.name === state.selectedProvince)[0]
                setTimeout(() => {
                    state.map.fitBounds(prov.coordinates)    
                }, 300);
            } 
            else if (!state.selectedProvince) {
                state.map.fitBounds([[83.1355, -52.619], [36, -141.028]])
            } 
        }
    }, [state.selectedProvince])

    useEffect(()=> {
        console.log('new riding')
        try {
            if (state.map)  {
                if (state.selectedRiding) {

                    let getGeo=''
                    geoRef.current.eachLayer(layer=>{
                        if (layer.options.name.toLowerCase() === state.selectedRiding.toLowerCase()) {
                            getGeo = layer
                        }
                    })
                    if (getGeo.getBounds()) {
                        getGeo.setStyle(selectedStyle)
                        selectedRef.current = getGeo
                        state.map.fitBounds(getGeo.getBounds())
                    }
                } 
            }    
        } catch(e) {
            console.log(e)
        }
    }, [state.selectedRiding])

    const handleSetMap = (e) => {
        e.options.minZoom = e.getZoom()
        dispatch({type: 'SET_MAP', map: e})
    }

    const resetBounds = () => {
        if (selectedRef.current) {
            selectedRef.current.setStyle(defaultStyle)
            selectedRef.current = null
        }
        state.map.fitBounds([[83.1355, -52.619], [36, -141.028]])
        dispatch({type: 'RESET'})
    }


    const handleFill = (ridingName) => {
        let fillColor = 'lightgrey'
        try {
            let ridingResults = data.full.data.filter(ed=>{
                return ed.name.toLowerCase() === ridingName.toLowerCase()
            })[0]
            
            let party = data.party.partyResults.filter(party=>{
                return party.nameShort === ridingResults.results[0].partyCode
            })[0]
            if (party) {
                if (ridingResults.results[0].votes) {
                    fillColor = party.color
                } else {
                    fillColor = '#C0C0C0'
                }
            } else {
                fillColor = '#C0C0C0'
            }
            return {results: ridingResults, color: fillColor}
        } catch (e) {
            console.log('error fill', e)
            return {results: null, color: '#C0C0C0'}
        }
    }

    function resetFeature(e) {
        togglePopup(false)
        if (selectedRef.current) {
            if (e.layer.feature.properties.FEDENAME_ === selectedRef.current.options.name) {
                return;
            }
        } 
        e.layer.setStyle(defaultStyle)
    }

    const handleClick = (e) => {
        if (Date.now() - counter < 160) {
            return;
        }
        try {
            // if (selectedRef.current) {
            //     if (selectedRef.current.options.name !== e.layer.options.name) {
            //         selectedRef.current.setStyle(defaultStyle)
            //     }
            // }
            
            var layer = e.layer
            selectedRef.current = e.layer;
            var layerBounds = e.layer.getBounds();
            let results = handleFill(e.layer.feature.properties.FEDENAME_)
            setTimeout(()=> {
                state.map.fitBounds(layerBounds)

                if (e.layer.feature.properties.FEDENAME_ === "Nunavut" || e.layer.feature.properties.FEDENAME_ === "Northwest Territories" || e.layer.feature.properties.FEDENAME_ === "Yukon") {
                    dispatch({type: 'CLICK_RIDING', riding: layer.feature.properties.FEDENAME_, province: "Territories", ridingResults: results})
                } else {
                    dispatch({type: 'CLICK_RIDING', riding: layer.feature.properties.FEDENAME_, province: layer.feature.properties.PROVNAME_, ridingResults: results})
                }                
            },200)
            counter = Date.now()

        } catch(e) {
            console.log('error click', e)
        }
        
    }

    return (
        <div className={classes.mapRoot} >
            {(declaration && showBanner) && <Declaration />}
            <div className={classes.mapHolder}>
            <MapContainer 
                bounds={[[83.1355, -52.619], [36, -141.028]]}
                // maxBounds={[[89.1355, -40.619], [35.711, -150.028]]}
                zoomSnap={0.25}
                zoomDelta={0.25}
                whenCreated={handleSetMap}
                maxZoom={12}
                // tap={false}
                >
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url=""
                    className={'leaflet-bottom leafleft-left'}
                    />
                    <LayerGroup ref={geoRef}>
                    {geoJSON ? geoJSON.features.map((geo,i)=> {
                        const getResults = handleFill(geo.properties.FEDENAME_)
                        const generateKey = () => {
                            return `${geo.properties.FEDENAME_}-${getResults.color}`
                        }
                        return <GeoJSON
                            data={geo}
                            key={generateKey()}
                            name={geo.properties.FEDENAME_}
                            style={()=>{return ({
                                weight: 0.6,
                                fillOpacity: state.selectedRiding === geo.properties.FEDENAME_ ? 0.8 : 0.7,
                                color: 'black',
                                fillColor: getResults.color})}
                            }
                            eventHandlers={{
                                mouseover: (e)=>e.layer.setStyle(selectedStyle),
                                mouseout: resetFeature,
                                click: handleClick,
                            }}
                        >
                            {(!isMobile) &&
                            <Tooltip 
                                key={`${data.counter}-${i}`}
                                ref={tooltipRef}
                                sticky
                                >
                                <MyTooltip results={getResults.results} color={getResults.color} riding={geo.properties.FEDENAME_}/>
                            </Tooltip>                        
                            }
                        </GeoJSON>
                    }): <div className={classes.loading}>
                           <div className="lds-ring"><div></div><div></div><div></div><div></div></div> 
                        </div>}
                    </LayerGroup>
                <div style={{top: 90}} className={'leaflet-top leaflet-left'}>
                    <div  className='leaflet-control'>
                        {/* <button onClick={()=>console.log(state.map.getBounds())}>get bounds</button> */}
               
                        <a id="zoomOut" 
                            style={{color: 'black !important', border: '2px solid rgba(0,0,0,0.25)'}} 
                            className={`leaflet-control-zoom leaflet-bar ${classes.resetButton}`} 
                            onClick={resetBounds}
                            >
                            <ZoomOutMapIcon />
                        </a>
                    </div>
                </div>
            </MapContainer>
            </div>
            {(isMobile && state.ridingResults) && (state.showPopup && <div className={classes.mobilePopup}>
                <MyTooltip results={state.ridingResults.results} showX={true} color={state.ridingResults.color} riding={state.selectedRiding}/>
            </div>)}
        </div>
    )
}

export default Map;