import React, { useContext, useState, useEffect } from 'react'

import {makeStyles} from '@material-ui/core/styles'
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts'

import {DataContext} from '../../dataContext'
import { Button,ButtonGroup, FormControlLabel, Switch } from '@material-ui/core';

import PartyCard from './partyCard'
import GainsDiv from './GainsDiv'
import Declaration from './Declaration'

const styles = makeStyles({
	main: {
		fontSize: (props) => props.small && 12,
		// fontFamily: 'Roboto',
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		padding: 5,
		backgroundColor: '#ededf0',
		alignContent: 'center',
		height: 260
	}, 
	topDiv: {
		display: 'flex', 
		justifyContent: 'space-between',
		gap: '2%',
		borderBottom: '1px solid grey',
		'& button': {
			fontFamily: 'proxima-nova-bold !important', 
		},
		'& #contained': {
			color: '#3f51b5', 
			backgroundColor: '#3f51b529', 
		}, 
		'& #electionyeargroup': {
			paddingBottom: 2
		}
	},
	declarationDiv: {
		flex: 2,
		alignSelf: 'center',
	},
	tabDiv: {
		display: 'none', 
		flex: 2,
		'& #tab': {
			background: '#9e9e9e',
			color: 'white',
			flex: 1,
			fontSize: 9,
			borderBottomRightRadius: 0, 
			borderBottomLeftRadius: 0,
			padding: 5, 
			border: '1px solid black',
			borderBottomColor: 'transparent'
		},
		'& #active': {
			background: '#ededf0',
			color: 'black',
			flex: 1,
			fontSize: 9,
			minWidth: 70,
			borderBottomRightRadius: 0, 
			borderBottomLeftRadius: 0,
			boxShadow: '0px 2px 0px -1px #ededf0',
			padding: 5, 
			border: '1px solid black',
			borderBottomColor: '#ededf0'
		}
	},
	content: {
		display: 'flex', 
		flexDirection: 'row', 
		// flexDirection: props=>props.small ? 'column' : 'row',
		// padding: '5px 0px',
		margin: 5,
		flexGrow: 1
	},
	left: {
		flex: 3,
		// maxHeight: 200,
		alignItems: 'center',
		
	},
	right: {
		flex: 3,
		maxHeight: 200

	},
	title: {
		fontWeight: 'bold',
		marginLeft: 5,
		fontSize: props=> props.small ? 16 : 18
	}, 
	border: {
		borderRight: '1px solid grey',
		marginRight: 10
	},
	chartHolder: {
		display: 'flex',
		flexDirection: 'row',
		// height: '100%',
		alignItems: 'center',
		position: 'relative'
	},
	partyBars: {
		flex: 2,
		alignSelf: 'center',
	},
	chartDiv: {
		padding: '0 10px', 
		flex: 2,
		height: '100%',
		width: '100%'
	},
	update: {
		paddingTop: 5,
		fontSize: 12
	}

})


export default function GraphApp() {

	const [declarationText, setDeclarationText] = useState('')
	const [hide, toggleHide] = useState(window.innerWidth > 600 ? '' : 'gains')
	const isIe = isIE();
	const classes = styles()

	let votesRef = 0;

    const {data, setYear} = useContext(DataContext)

    useEffect(()=>{

        try {
            if (data.party) {
				if (data.currentYear) {
					if (data.declaration.overallResult.partyName && data.declaration.overallResult.resultText) {
						let text = data.declaration.overallResult.partyName + ' ' + data.declaration.overallResult.resultText;
						if (text===declarationText) {
							return;
						} else {
							setDeclarationText(text)
						}
					} else 
					setDeclarationText('')
				} else {
					if (data.prevOverall.overallResult.partyName && data.prevOverall.overallResult.resultText) {
						let text = data.prevOverall.overallResult.partyName + ' ' + data.prevOverall.overallResult.resultText;
						if (text===declarationText) {
							return;
						} else {
							setDeclarationText(text)
						}
					} else 
					setDeclarationText('')

				}
            }
        } catch (e) {
            
        }
    
      },[data])

	 const CustomTooltip = ({active, payload, label}) => {
		if (active && payload && payload.length) {
			return (
				<div className="tooltip-wrapper">
					<strong className="tooltip-name">{payload[0].name}</strong>
					<span>{payload[0].value.toLocaleString('en')} votes</span>
				</div>
			)
		}
		return null
	 }


	function isIE() {
		const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
		const msie = ua.indexOf('MSIE '); // IE 10 or older
		const trident = ua.indexOf('Trident/'); //IE 11
		
		return (msie > 0 || trident > 0);
	}

    return (
	<div id="graphWidget-main" className={classes.main}>
	  <div className={classes.topDiv}>
	  	<div className={classes.declarationDiv}>
			{declarationText && 
				<Declaration declarationText={declarationText} />
			}	
		</div>	
	  	{data.config && 
		  	<ButtonGroup id="electionyeargroup">
				<Button id={data.currentYear ? "": "contained"} onClick={()=>setYear(false)}>{data.config.prevElection}</Button>
				<Button id={!data.currentYear ? "": "contained"} onClick={()=>setYear(true)}>{data.config.currentElection}</Button>
			</ButtonGroup>
		}
			<ButtonGroup className={classes.tabDiv} id="tabDiv">
				<Button onClick={()=>toggleHide('gains')} id={hide === 'gains' ? "active" : "tab"}>Pop. Vote</Button>
				<Button onClick={()=>toggleHide('chart')} id={hide === 'chart' ? "active" : "tab"}>Gains/ Losses</Button>
			</ButtonGroup>
		</div>
		<div className={classes.content}>
			
			
			<div className={classes.left} style={{display: hide === 'chart' ? 'none': 'initial'}}>
				<div className={classes.title}>Popular vote</div>
				<div className={classes.chartHolder} >
					<div className={classes.partyBars}>
						{data.party &&
							data.currentYear ?
							data.party.partyResults.map((party,i)=>{
							if (data.party.partyResults.length > 7) {
								if (i < 6) {
								votesRef += party.votesPercent
								return <PartyCard key={i} party={party} result={`${party.votesPercent}%`} />
								}
								else if (i === data.party.partyResults.length -1) {
									return <PartyCard key={i} party={{nameShort: 'OTH', color: '#c0c0c0'}} result={`${votesRef ? (100-votesRef).toFixed(1):0}%`} /> 
								}
							} else				
							return <PartyCard key={i} party={party} result={`${party.votesPercent}%`} />
						}) : 
						(data.prev && data.prev.partyResults.map((party,i)=>{
							if (data.prev.partyResults.length > 7) {
								if (i < 6) {
								votesRef += party.votesPercent
								return <PartyCard key={i} party={party} result={`${party.votesPercent}%`} />
							}
							else if (i === data.prev.partyResults.length -1) {
								return <PartyCard key={i} party={{nameShort: 'OTH', color: '#c0c0c0'}} result={`${votesRef ? (100-votesRef).toFixed(1):0}%`} /> 
							}
							} else				
							return <PartyCard key={i} party={party} result={`${party.votesPercent}%`} />
						}) )
						
						}
					</div>
						<div className={classes.chartDiv} >
							{data.party &&
							(<ResponsiveContainer height={190} width={'100%'} >
							 	{data.currentYear ?
									(data.party.partyResults[0].votes ?
										<PieChart  >
											<Pie
												data={data.party.partyResults}
												fill="#8884d8"
												// label={(entry)=>{return entry.name}} 
												paddingAngle={0}
												dataKey="votes"
												
											>
											{data.party.partyResults.map((entry, index) => {
													return <Cell key={`cell-${index}`} fill={entry.color} />

												}
											)}
											</Pie>
											{!isIe && <Tooltip position={{x:0, y:-20}}  content={<CustomTooltip />} />}
										</PieChart>
										:
										<PieChart>
											<Pie
												data={[{name: 'No Results', value: 100}]}
												startAngle={90}
												endAngle={450}
											>
												<Cell fill="grey" />
											</Pie>
											{!isIe && <Tooltip position={{x:0, y:-10}} content={<div className="tooltip-wrapper">
												<strong>No Results</strong>
											</div>}/>}

									</PieChart>)	: <PieChart  >
									<Pie
										data={data.prev.partyResults}
										fill="#8884d8"
										// label={(entry)=>{return entry.name}} 
										paddingAngle={2}
										dataKey="votes"
										
									>
									{data.prev.partyResults.map((entry, index) => {
											return <Cell key={`cell-${index}`} fill={entry.color} />

										}
									)}
									</Pie>
									{!isIe && <Tooltip position={{x:0, y:-20}}  content={<CustomTooltip />} />}
								</PieChart>
							}
							</ResponsiveContainer>)
							}
						</div>	
				</div>
			</div>
			{!hide && <div className={classes.border} id="chartBorder"/>}
			<div className={classes.right} id="gainslosses" style={{display: hide === 'gains' ? 'none': 'initial'}}>
				<div className={classes.title}>Gains/Losses</div>
				<div className={classes.chartHolder}>
					<div className={classes.partyBars}>
						{data.party && (data.currentYear ? 
						data.party.partyResults.map((party,i)=>{
							if (data.party.partyResults.length > 7) {
								if (i < 6) {
									return <PartyCard key={i} party={party} result={`${party.seatChange}`} />
								}
								else if (i === data.party.partyResults.length -1) {
									return <PartyCard key={i} party={{nameShort: 'OTH', color: '#c0c0c0'}} result={`${party.seatChange}`} /> 
								}	
							} else {
								return <PartyCard key={i} party={party} result={`${party.seatChange}`} />
							}
						}): 
						data.prev.partyResults.map((party,i)=>{
							if (data.prev.partyResults.length > 7) {
								if (i < 6) {
									return <PartyCard key={i} party={party} result={`${party.seatChange}`} />
								}
								else if (i === data.prev.partyResults.length -1) {
									return <PartyCard key={i} party={{nameShort: 'OTH', color: '#c0c0c0'}} result={`${party.seatChange}`} /> 
								}	
							} else {
								return <PartyCard key={i} party={party} result={`${party.seatChange}`} />
						}}))}
					</div>
					{/* <GainsChart data={data.party} /> */}
					<GainsDiv data={data.currentYear ? data.party : data.prev}/>
				</div>
			</div>
        </div>
		{/* <div>
			<div className={classes.update}>Last updated: { Date(data.generated) }</div>
		</div> */}
    </div>
    )
}