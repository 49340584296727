import React, {useContext, useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core'

import {DataContext} from '../../dataContext'
import { Button, ButtonGroup, FormControlLabel, Switch } from '@material-ui/core';

//Components
import MajorityMeter from './MajorityMeter';
import Declaration from './Declaration';

const styles = makeStyles({
	barchartApp: {
		height: 250,
		position: "relative",
	},
	main: {
		// fontFamily: 'Roboto',
		height: '100%',
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		padding: '10px 10px',
		backgroundColor: '#ededf0'
	},
	titleRow: {
		flex:2,
		display: 'flex', 
		padding: '0px 5px 5px 5px',
		justifyContent: 'space-between',
		'& #electionyeargroup': {
			height: 40
		},
		'& button': {
			fontFamily: 'proxima-nova-bold !important', 
		},
		'& #contained': {
			color: '#3f51b5', 
			backgroundColor: '#3f51b529', 
		}, 
	},
	title: {
		fontSize: 24,
		// paddingBottom: 10, 
		fontWeight: 'bold',
		display: 'flex', 
		gap: '50px',
		alignItems: 'center', 
		'& label': {
			border: '1px solid grey',
			borderRadius: 5,
		},
		'& .MuiFormControlLabel-label': {
			fontFamily: 'proxima-nova !important', 
			fontSize: 14,
			paddingRight: 10
		}
	},
	declarationText: {
		paddingTop: 5,
		'& #party' : {
			fontWeight: 'bold'
		},
		'& #majority': {
			fontSize: 14
		}
	},
	barchart: {
		fontSize: 14,
		display: 'flex',
		padding: '15px 0px 10px 0px',
		flexDirection: 'column-reverse',
		flexWrap: 'wrap',
		flex:3, 
	},
	partyMap: {
		fontSize: 16,
		display: 'flex',
		flex: 2,
		alignContent: 'left',
		overflowX: 'auto',
		maxHeight: 52
	},
	update: {
		fontSize: 10,
		padding: '5px 0px 0px 5px'

    },
    partyContainer: {
        width: '20%',
		paddingTop: 5,
		display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
		fontSize: 14,
        letterSpacing: 2,
        padding: '5px 5px',
        margin: 1,
        borderRadius: 5,
        color: 'white',
        justifyItems: 'center',
		justifyContent: 'space-between',
    },
    popVote:{
        // paddingTop: 5,
        fontWeight: 'bold',
        fontSize: 14
    }
})


const BarchartApp = () => {
    const {data, setYear} = useContext(DataContext)
    const classes = styles()

	const [parties, setParties] = useState([])
	const [declarationText, setDeclaration] = useState({
		text: '', 
		elected: false
	})


    const seatInfo = {
        total: 338,
        majority: 170
    }

	const date = new Date()

	const getDate = (update) => {
		const date = new Date(update)
		return date.toString().split(' ').slice(1, 5).join(' ')

	}



    useEffect(()=> {
        if (data.party) {
			let total = 0
			setParties([])
			if (data.currentYear) {
				data.party.partyResults.map((party, i)=>{
					if (i <=data.party.partyResults.length-1 && i >5) {
						
						if (!party.seats) {
							if (party.isMainParty && party.nameShort !== 'IND') {
								setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, color: party.color,votesPercent: party.votesPercent,votesTotal: party.votes}])
							}
							return;
						}
					}
					setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, color: party.color,votesPercent: party.votesPercent,votesTotal: party.votes}])
					
				})
			} else {
				data.prev.partyResults.map((party, i)=>{
					if (i >5) {
						if (!party.seats) {
							if (party.isMainParty && party.nameShort !== 'IND') {
								setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, color: party.color,votesPercent: party.votesPercent,votesTotal: party.votes}])
							}
							return;
						}
					}
					setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, color: party.color,votesPercent: party.votesPercent,votesTotal: party.votes}])
					
				})
			}
            
		}
		let winner;
		let leading = false;
		if (data.config) {
			if (data.currentYear) {
				if (data.declaration.overallResult.resultText) {
					let text = data.declaration.overallResult.partyName + ' ' + data.declaration.overallResult.resultText;
					setDeclaration({elected: true, text: text})	
				} else {
					if (data.party.partyResults[0].seats > data.party.partyResults[1].seats) {
						winner = data.party.partyResults[0].name
						leading = true
						setDeclaration({elected: false, text: winner + ' leading - ' + data.config.majority + ' seats needed for majority'})
					} else {
						setDeclaration({ elected: false, text: data.config.majority + ' seats needed for majority'})
					}
				}
			} else {
				if (data.prevOverall.overallResult.resultText) {
					setDeclaration({elected: true, text: data.prevOverall.overallResult.partyName + ' ' + data.prevOverall.overallResult.resultText})
				}
			}
	
		}
    }, [data])

    const Party = (props) => {
		const {party} = props
		return (
			<div key={party.id} id="partyCard" className={classes.partyContainer} style={{backgroundColor: party.color}}>
				<div>{party.partyName}</div>
				<div id="popVote" className={classes.popVote}> {party.seats} </div>
			</div>
		);
	}


    return (
        <div className={classes.barchartApp}>
            {data.party && 
             <div className={classes.main}>
                <div className={classes.titleRow}>
					<div className={classes.left}>
						
						<div id="widgetTitle" className={classes.title}><span>{data.config.title}</span>
							
						</div>
						
						<Declaration elected={declarationText.elected} declarationText={declarationText}/>
						
					</div>
					<ButtonGroup id="electionyeargroup">
						<Button id={data.currentYear ? "": "contained"} onClick={()=>setYear(false)}>{data.config.prevElection}</Button>
						<Button id={!data.currentYear ? "": "contained"} onClick={()=>setYear(true)}>{data.config.currentElection}</Button>
					</ButtonGroup>
				</div>
                <div className={classes.barchart}>
                    <MajorityMeter seatTotal={seatInfo.total} majority={seatInfo.majority} majorityPercent={(seatInfo.majority/seatInfo.total)*100} data={data.currentYear ? data.party : data.prev}/>
                </div>
                
                <div className={classes.partyMap}>
                    {parties.map(party=>{
						return <Party key={party.id} party={party}/>
					})}
                </div>
                <div className={classes.update}>Last updated: {getDate(data.party.generated)}</div>
            </div>
            }
        </div>
    )
}

export default BarchartApp