import React, {useEffect, useReducer, createContext, useState} from 'react'

import {useLocation, useParams} from 'react-router-dom'



export const DataContext = createContext(null);

const initialData = {full: null,
    party: null,
    declaration: null,
    config: null,
    prev: null,
    currentYear: true,
    counter: 0}

const DataContextProvider = (props) => {
    // const [data, dispatch] = useReducer(dataReducer, initialData)
    const [data, setData] = useState(initialData)
    let {year} = useParams()
    let location = useLocation()
    let currentTime = new Date ()

    const getStaticData = () => {
        console.log('fetching initial...')
  
        Promise.all([
                fetch(`data/${year}/ridingResults.json`)
                    .then(res=>{
                        return res.json();
                    })
                    .then(json=>{
                        return json
                    }),
                fetch(`data/${year}/partyResults.json`)  //http://electorfederal.blcloud.net/api/party/result/overall/(all)/json
                    .then(res=>{
                        return res.json();
                    })
                    .then(json=>{
                        return json
                    }),
                fetch(`data/${year}/overallResults.json`)  // //http://electorfederal.blcloud.net/api/OverallResult/?format=json
                    .then(res=>{
                        return res.json();
                    })
                    .then(json=>{
                        return json
                    }),
                fetch(`data/${year}/prevResults.json`)  
                    .then(res=>{
                        return res.json();
                    })
                    .then(json=>{
                        return json
                    })
                    .catch(e=>{
                        return null
                    }),
                fetch(`data/${year}/prevOverall.json`)  
                    .then(res=>{
                        return res.json();
                    })
                    .then(json=>{
                        return json
                    })
                    .catch(e=>{
                        return null
                    }),
                fetch(`data/${year}/electionconfig.json`)  // //http://electorfederal.blcloud.net/api/OverallResult/?format=json
                    .then(res=>{
                        return res.json();
                    })
                    .then(json=>{
                        return json
                    })
                    .catch(e=>{
                        console.log('Please add election config')
                    })
                ])
                .then(res=>{
                    console.log(res)
                    setData(prevData=>({
                        ...prevData,
                        type: 'SET_INITIAL', 
                        full: res[0], 
                        party: res[1],
                        declaration: res[2],
                        prev: res[3],
                        prevOverall: res[4],
                        config: res[5] 
                    }))
                }).catch(e=>{
                    console.log('error', e)
                })
        
    }

    const getDynamicData = () => {
        console.log('fetching dynamic..')
        Promise.all([
            fetch(`data/${year}/ridingResults.json`, {cache:"no-cache"})
                .then(res=>{
                    return res.json();
                })
                .then(json=>{
                    return json
                }),
            fetch(`data/${year}/partyResults.json`, {cache:"no-cache"})  //http://electorfederal.blcloud.net/api/party/result/overall/(all)/json
                .then(res=>{
                    return res.json();
                })
                .then(json=>{
                    return json
                }),
            fetch(`data/${year}/overallResults.json`, {cache:"no-cache"})  // //http://electorfederal.blcloud.net/api/OverallResult/?format=json
                .then(res=>{
                    return res.json();
                })
                .then(json=>{
                    return json
                })])
            .then(res=>{
                setData(prevState=>({
                    ...prevState,
                    full: res[0], 
                    party: res[1],
                    declaration: res[2],
                    counter: prevState.counter+1
                }))
            }).catch(e=>{
                console.log('error', e)
            })
    }


    useEffect(()=> {
        getStaticData();
    }, [])

    useEffect(()=> {
        let interval;
        if (data.config) {
            console.log('updating every ' + (data.config.timer ? data.config.timer : 30000) + 'ms');
            interval = setInterval(()=>{
                getDynamicData();
                
            },(data.config.timer ? data.config.timer : 30000))
        }
        return () => {
            clearInterval(interval)
        }
    }, [data.config])

    const setYear = (year) => {
        console.log('change year')
        if (year !== data.year) {
            setData(prevState=>({
                ...prevState,
               currentYear: year
            }))
        }
    }


    return (
        <DataContext.Provider value={{data, setYear}}>
            {props.children}
        </DataContext.Provider>
    )
}

export default DataContextProvider