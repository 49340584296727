import React, {useContext, useState, useEffect} from 'react'
import {IconButton, Paper, makeStyles, TextField} from '@material-ui/core'

import {
    useParams
} from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import SearchIcon from '@material-ui/icons/Search';

import {Autocomplete} from '@material-ui/lab'

import AccordionBody from './AccordionBody'

import {MapContext} from './mapContext'
import {DataContext} from '../../dataContext'

const styles = makeStyles({
    root: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden'
    }, 
    title: {
        backgroundColor: '#9e9e9e',
        fontFamily: 'proxima-nova-semibold',
        boxShadow: '-1px 1px 2px 0px black',
        zIndex: 402,
        fontSize: 26,
        fontWeight: 600,
        color: 'white',
        maxHeight: 80,
        display: 'flex', 
        alignItems: 'center', 
        textAlign: 'center',
        padding: '10px 5px',
        justifyContent: 'center'
    },

    results: {
        backgroundColor: 'white',
        flexGrow: 1,
        overflow: 'hidden',
        height: '100%'
    },
    accordionBody: {
        display: 'flex', 
        flexDirection: 'column'
    },
    resultsSummary: {
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center', 
        fontSize: 14,
        fontWeight: 600,
        '& #votes': {
            fontSize: 12
        }
    },
    backButton: {
        minHeight: '48px',
        display: 'flex', 
        alignItems: 'center', 
        paddingRight: 2, 
    },
    autoComplete: {
        width: '100%',
        height: 65,
        display: 'flex',
        // position: 'sticky', 
        // top: 0,
        paddingTop: 6,
        backgroundColor: 'white', 
        zIndex: 100,
        alignItems: 'center',
        '& .MuiAutocomplete-root': {
            width: '95% !important',
            margin: '6px 6px 6px 6px',
            flexGrow: 1
        },
        '& .MuiOutlinedInput-root': {
            // padding: '3px !important'
        }, 
        '& .MuiFormLabel-root': {
            lineHeight: 1
        },
    }
})

const Sidebar = (props) => {

    const {state, dispatch} = useContext(MapContext)
    const {data} = useContext(DataContext)

    const [menu, setMenu] = useState('provinces')
    const [selectedProvince, setProvince] = useState('')
    const [expanded, setExpanded] = useState('')
    const [offset, setOffset] = useState(90)
    const [search, setSearch] = useState('')
    const [input, setInput] = useState('')

    const classes = styles();

    useEffect(() => {
        setExpanded(state.selectedRiding)
        return ()=> {
            setExpanded('')
        }
        
    }, [state.selectedRiding])

    useEffect(()=> {
        if (state.menu === 'provinces') {
            setOffset(44)
        } else if (state.menu === 'ridings' && !state.selectedRiding) {
            setOffset(90)
        }
    }, [state.menu])

    useEffect(()=> {
        if (expanded) {
            setTimeout(()=> {
                try {
                    var scrollingDiv = document.getElementById("ridingMenu")
                    let menuel = document.getElementById(state.selectedRiding);
                    // var topPos = menuel.offsetTop - offset
                    console.log(scrollingDiv.scrollTop, menuel)
                    scrollingDiv.scrollTop = menuel.offsetTop
                    // window.scrollTo(0,0)
                    setOffset(90)    
                } catch(e) {

                }

            }, 300)
        }
    }, [expanded])

    const handleClickProvinceButton = (e) => {
        // console.log(e.target.getAttribute('value'))
        let value = e.target.getAttribute('value')
        dispatch({type: 'CLICK_PROVINCE', province: value, menu: 'ridings'})
        // setMenu('ridings');
        // setProvince(value);
    }
    const handleFill = (ridingName) => {
        
        let fillColor = 'lightgrey'
        try {
            let ridingResults = data.full.data.filter(ed=>{
                return ed.name.toLowerCase() === ridingName.toLowerCase()
            })[0]
            
            let party = data.party.partyResults.filter(party=>{
                return party.nameShort === ridingResults.results[0].partyCode
            })[0]
            if (party) {
                if (ridingResults.results[0].votes) {
                    fillColor = party.color
                } else {
                    fillColor = '#C0C0C0'
                }
            } else {
                fillColor = '#C0C0C0'
            }
            return {results: ridingResults, color: fillColor}
        } catch (e) {
            console.log('error fill', e)
            return {results: null, color: '#C0C0C0'}
        }
    }
    const handleAccordion = (riding) => {
        if (expanded === riding) {
            setExpanded('')
        } else 
        setExpanded(riding)
        dispatch({type: 'CLICK_RIDING', riding: riding, province: state.selectedProvince, ridingResults: handleFill(riding)})
    }

    const handleSearch = (newSearch) => {
        // console.log(newSearch)
        if (newSearch) {
            dispatch({type: 'CLICK_RIDING', riding: newSearch.FEDENAME_, province: newSearch.PROVNAME_, ridingResults: handleFill(newSearch.FEDENAME_)})            
        }

        // if( /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        // }
    }

    return (
        <div className={classes.root}>
            <div id="sidebarTitle" className={classes.title}>
                <div >
                    {state.menu === "provinces" ? "Select a Province to filter Ridings" : "Select a riding to view results"}
                </div>
            </div>
            <div className={classes.results}>
                <CSSTransition
                    in={state.menu==="provinces"} 
                    timeout={600}
                    unmountOnExit
                    classNames={{
                        ...styles,
                        enter: 'enter-right',
                        enterActive: 'enter-right-active',
                        exit: 'exit-left',
                        exitActive: 'exit-left-active'
                    }}
                >
                    <div className="menu" id="provinceMenu">
                        <div className="scrollingDiv">
                        {/* <div id="provinceButton"  onClick={handleClickProvinceButton} value={''}>
                            All Ridings
                        </div> */}
                        {state.provinceData.prov.map((province,i)=>{
                            return (<div id="provinceButton" key={i} value={province.name} onClick={handleClickProvinceButton}>
                                {province.name}
                            </div>)
                        })}
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={state.menu==="ridings"} 
                    timeout={600}
                    unmountOnExit
                    classNames={{
                        ...styles,
                        enter: 'enter-left',
                        enterActive: 'enter-left-active',
                        exit: 'exit-right',
                        exitActive: 'exit-right-active'
                    }}
                >
                    <div className="menu">
                        <Paper key="autocomplete" className={classes.autoComplete}>
                            <div className={classes.backButton} onClick={()=>{setExpanded(''); dispatch({type: 'RESET'})}}><NavigateBeforeIcon style={{paddingRight: 5}} />
                                <div style={{display: 'flex', alignItems: 'center'}}>Back</div>
                            </div>
                            <Autocomplete
                                id="combo-box-demo"
                                onChange={(event, newValue) => {
                                    handleSearch(newValue)
                                    }}
                                onInputChange={(event, newValue)=>{
                                    setInput(newValue)
                                }}
                                options={state.provinceData.ED.filter(riding=>{
                                    if (state.selectedProvince) {
                                        if (state.selectedProvince === 'Territories') {
                                            return (riding.PROVNAME_ === 'Territories')
                                        } else
                                            return riding.PROVNAME_ === state.selectedProvince
                                    } else return riding
                                })
                                // .filter(label=>{
                                //     console.log(label.FEDENAME_.startsWith(input))
                                //     if (!input) {
                                //         return true
                                //     } else return label.FEDENAME_.toLowerCase().startsWith(input.toLowerCase())
                                // })
                                // .sort((a, b)=> {
                                //     if (a.FEDENAME_ > b.FEDENAME_) {
                                //         return 1
                                //     } else return -1
                                // })
                                .sort((a,b)=>{
                                    if (input) {
                                        if (a.FEDENAME_.toLowerCase().startsWith(input.toLowerCase())) {
                                            if (a.FEDENAME_.toLowerCase().startsWith(input.toLowerCase()) && b.FEDENAME_.toLowerCase().startsWith(input.toLowerCase())) {
                                                if (a.FEDENAME_ > b.FEDENAME_) {
                                                    return 1
                                                } else return -1
                                            } else return -1
                                        } else return 1
                                    } else {
                                        if (a.FEDENAME_ > b.FEDENAME_) {
                                            return 1
                                        } else return -1
                                    }
                                })
                            }
                                getOptionLabel={(option) => option.FEDENAME_}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField id="autocompleteTextfield" disabled {...params} label={`Search ${state.selectedProvince} Ridings`} variant="outlined" />}
                                />
                        </Paper>
                        <div  className="scrollingDiv ridingMenu" id="ridingMenu">
                            {state.menu === 'ridings' && state.provinceData.ED.filter(riding=>{
                                if (state.selectedProvince) {
                                    if (state.selectedProvince === 'Territories') {
                                        return (riding.PROVNAME_ === 'Territories')
                                    } else
                                        return riding.PROVNAME_ === state.selectedProvince
                                } else return riding
                            })
                            .sort((a, b)=> {
                                if (a.FEDENAME_ > b.FEDENAME_) {
                                    return 1
                                } else return -1
                            })
                            .map((contest, i)=>{
                                return (
                                    <Accordion key={i} id={contest.FEDENAME_} expanded={expanded === contest.FEDENAME_} onChange={()=>handleAccordion(contest.FEDENAME_)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            {contest.FEDENAME_}
                                        </AccordionSummary>
                                        {expanded === contest.FEDENAME_ ? <AccordionBody id={i} riding={contest.FEDENAME_}/> : <div />}
                                    </Accordion>
                                )
                            })}

                        </div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    )
}

export default Sidebar;

