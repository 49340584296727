import React, {useState, useEffect} from 'react'

import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
    meter: props =>({
        width: '100%',
        // marginLeft: 10,
        position: 'relative',
        marginTop: 14, 
        marginRight: 5
    }),
    bar: {
      background: (props) =>
        props.color, 
      width: (props) => props.votes,
      borderRadius: '0px 5px 5px 0px',
      minWidth: 5,
      height: 5,
      margin: 1,
    },
    majorityLine: {
        position: 'absolute',
        height: '110%',
        bottom: -2,
        borderLeft: (props)=> props.small ? '1px solid black' : '2px solid black',
    },
    majorityLabel: props=>({
        position: 'absolute',
        marginTop: props.small ? -14 : -18,
        paddingRight: 5,
        fontSize: 12,
        fontWeight: 'bolder',
        transform: 'translate(-20px, 0px)'
    })


  });
  

function Bar(props) {
    const { color, votes, ...other } = props;
    const classes = useStyles(props);
    return <div id="seatBar" className={classes.bar} {...other} />;
}

const MajorityMeter = (props) => {
    
    // const { color, ...other } = props;

    const [maxSeats, setMaxSeats] = useState(25)
    const [difference, setDifference] = useState(0);
    const [majorityPosition, setMajorityPosition] = useState(50);
    const [leading, setLeading] = useState(0)
    const classes = useStyles(props)
    
    useEffect(()=> {
        if (props.data) {
            let leadingParty = props.data.partyResults[0];
            // console.log('leading', props.seatTotal)
            if (leadingParty.seats >= props.majority) {
                setMaxSeats(leadingParty.seats);
                setMajorityPosition(props.majority/leadingParty.seats*100)
            } else {
                setMaxSeats(props.majority)
                setMajorityPosition(props.majority/props.majority*100)
            }
        }
    }, [props.data,props.seatTotal, props.majority])

    return (
        <div className={classes.meter}>
            <div className={classes.majorityLabel} style={{left: `${majorityPosition+2}%`, top: 0}}>170</div>

            {/* <div className={classes.majorityLabel} style={{right: `${100-majorityPosition}%`}}>{props.majority} seats needed for majority</div> */}
            {props.data && props.data.partyResults.map((party, i)=>{
                if (i > 5) {
                    console.log('bar', party)

                    if (!party.seats) {
                        if (party.isMainParty && party.nameShort !== 'IND') {
                            console.log(party)
                            return <Bar key={i} color={party.color} votes={party.seats > 0 ? `${(party.seats/maxSeats)*100 + 2}%` : '0%'} />
                        }
                        else return;
                    } else {
                        return <Bar key={i} color={party.color} votes={party.seats > 0 ? `${(party.seats/maxSeats)*100 + 2}%` : '0%'} />

                    }

                } else {
                    return <Bar key={i} color={party.color} votes={party.seats > 0 ? `${(party.seats/maxSeats)*100 + 2}%` : '0%'} />
                }

            })    
            }
            {/* {console.log('majority',majority)} */}
            <div className={classes.majorityLine} style={{left: `${majorityPosition+2}%`}}/>

        </div>
    );
}
export default MajorityMeter;