import React, { useState } from "react";
import "./App.css";

import {
	HashRouter as Router, 
	Switch, 
	Route, 
	Link,
	useRouteMatch,
	useParams
  } from "react-router-dom";

//Components
import MapApp from './components/MapApp/MapApp';
import SeatApp from './components/SeatApp/SeatApp' 
import GraphApp from './components/GraphApp/GraphApp'
import BarchartApp from './components/BarchartApp/BarchartApp'
import InstantArticleApp from './components/InstantArticle/InstantArticleApp'
import FilteredResultsApp from "./components/FilteredResultsApp/FilteredResultsApp";

import DataContextProvider from './dataContext'

const App = () => {

	return (
		<Router>
		<Switch>
		  <Route exact path="/">
			  <Home />
		  </Route>
		  <Route path="/map/:year/:riding?">
			<DataContextProvider>
			  <MapApp showBanner={true}/>
			</DataContextProvider>
		  </Route>
		  <Route path="/seat/:year?">
			<DataContextProvider>
			  <SeatApp />
			</DataContextProvider>
		  </Route>
		  <Route path="/graph/:year?">
			<DataContextProvider>
			  <GraphApp />
			</DataContextProvider>
		  </Route>
		  <Route path="/barchart/:year?">
			<DataContextProvider>
			  <BarchartApp />
			</DataContextProvider>
		  </Route>
		  <Route path="/filteredResults/:year/:loadfilter?">
			<DataContextProvider>
			  <FilteredResultsApp />
			</DataContextProvider>
		  </Route>
		  <Route path="/instantarticle/:year?/:riding?">
			<DataContextProvider>
			  <InstantArticle />
			</DataContextProvider>
		  </Route>
		</Switch>
	  </Router>
	);
}

const Home = () => {
	return (
		<div>
			<ul>
				<li>
					<Link to="/">Home</Link>
				</li>
				<li>
					<Link to="/map/2021">Map App</Link>
				</li>
				<li>
					<Link to="/barchart/2021">Barchart App</Link>
				</li>
				<li>
					<Link to="/graph/2021">Graph App</Link>
				</li>
				<li>
					<Link to="/seat/2021">Seat App</Link>
				</li>
				<li>
					<Link to="/instantarticle/2021/">Instant Article</Link>
				</li>
				<li>
					<Link to="/filteredResults/2021">Playlist Results</Link>
				</li>
			</ul>
    	</div>
	)
}

const InstantArticle = () => {
	const {year, riding} = useParams();
	
	return (
		<div style={{display: "flex", flexDirection: 'column', height: '100%'}}>
			<div style={{width: '100%'}}>
				<InstantArticleApp />
			</div>
			<div style={{flexGrow: '1', width: '100%', position: 'relative', overflow: 'auto'}}>
				<MapApp showBanner={false} isIA={true}/>
			</div>
				{/* <InstantArticleApp />
				<MapApp /> */}
		</div>
	)
}

export default App;