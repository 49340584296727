import React from 'react';

import { makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const styles = makeStyles({
    candidateRoot: {
        position: 'relative',
        display: 'flex', 
        flexDirection: 'row',
        height: 100,
        padding: '5px 0'
    },
    resultsInfo: {
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
        '& #pollsReported': {
            fontSize: 12,
            fontWeight: 'bolder'
        },
        '& #totalVotes': {
            fontSize: 10,
            color: 'darkslategrey'
        }
    },
    imageDiv: {
        height: '100%',
        position: 'relative',
        // marginBottom: 25,
        minWidth: 50,
        width: props=>props.screensize < 700 ? 50 : 70,
        borderRadius: '8px',
        overflow: 'hidden',
        '& #imageContainer': {
            width: '100%',
            height: '85px',
            background: 'white',
            borderRadius: 4,
            backgroundColor: props=>props.partyData.color,
        },
        '& #partyCode': {
            position: 'absolute', 

            bottom: 8,
            color: 'white',
            width: '100%',
            textAlign: 'center'
        },
        '& img': {
            width: '100%',
            height: 'auto',
            transition: 'width 500ms'
        }
    },
    candidateLeftDiv: {
        flex: 3, 
        display: 'flex', 
        flexDirection: 'column',
        position: 'relative', 
        // justifyContent: 'space-between',
        padding: '10px 0 10px 10px',

        '& #candidateIncumbent': {
            fontSize: 13,
            fontWeight: 'lighter',
            position: 'absolute' , 
            bottom: 0
        }, 
        '& #partyCode': {
            fontFamily: 'proxima-nova-bold !important'
        }
    },
    candidateName: {
        '& #candidateFirst': {
            fontWeight: 'bolder',
            fontSize: props=>props.screensize < 700 ? 12 : 14,

        },
        '& #candidateLast': {
            fontWeight: 'bolder',
            fontSize: 18,
        }
    }, 
    candidateRightDiv: {
        flex: 2, 
        justifySelf: 'flex-end',
        display: 'flex', 
        flexDirection: 'column',
        padding: '10px 0',
        justifyContent: 'space-between',
        '& #candidateElected': {
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'flex-end',
            fontSize: props=>props.screensize < 700 ? 12 : 14,
        }
    },
    votesDiv: {
        textAlign: 'right',
        '& #votesPercent': {
            fontSize: props=>props.screensize < 700 ? 16 : 22,
            fontWeight: 'bolder'
        },
        '& #votesTotal': {
            fontSize: props=>props.screensize < 700 ? 12 : 14,
            fontWeight: 'lighter'
        }
    }
})

const Candidate = (props) => {

    const classes = styles(props)

    const {candidate, partyData} = props;

    const getHeadshot = () => {
        try {
            // console.log('here',candidate.cachedHeadFilename.split('\\')[3].split('.png')[0])
            return candidate.cachedHeadFilename.split('\\')[3].split('.png')[0].replace('_PP_', '_PPC_')
        } catch(e) {
            console.log('error loading headshots', candidate.cachedHeadFilename)
            return 'headshot'
        }
    }
    return (
        <div key={props.id} className={classes.candidateRoot}>
            <div style={{backgroundColor: partyData.color}} className={classes.imageDiv}>
                {/* <img ref={imgRef} alt="Candidate Headshot" name={candidate.name} onLoad={e=>e.target.style.width = "100%"} onError={(e) => { e.target.onError = null; e.target.src =`/img/no_headshot.png`}} src={`/image/${props.prov}/${candidate.cachedHeadFilename}`}/> */}
                <div id="imageContainer">
                    <img style={{width: props.showHeads ? '100%' : '0px'}} onLoad={props.handleLoad} onError={(e) => { e.target.onError = null; e.target.src =`headshots/headshot.jpg`}} src={`headshots/${getHeadshot()}.jpg`}/>
                </div>
                {/* {(orientation) && <div id="partyCode" style={{fontWeight: 'bolder',color: partyData.color === '#C0C0C0' ? 'black' : 'white' }}>{candidate.partyCode}</div>} */}
            </div>
            <div className={classes.candidateLeftDiv}>
                <div className={classes.candidateName}>
                    <div id="candidateLast">{candidate.name}</div>
                    {/* <div id="candidateFirst">{candidate.firstName}</div>
                    <div id="candidateLast" >{candidate.lastName}</div> */}
                </div>
                <div id="partyCode" style={{color: partyData.color, fontFamily: 'proxima-nova-semibold !important'}}>{candidate.partyCode}</div>

                <div id="candidateIncumbent">{candidate.isIncumbent && 'Incumbent'}</div>
            </div>
            <div className={classes.candidateRightDiv}>
                <div className={classes.votesDiv}>
                    <div id='votesPercent'>{candidate.percent}%</div>
                    <div id='votesTotal'>{candidate.votes.toLocaleString('en')} total votes</div>
                </div>
                {candidate.isElected ? <div id="candidateElected">
                    <CheckCircleIcon style={{paddingRight: 5, fontSize: 14, color: 'green'}} /> Elected
                </div>
                :
                <div style={{fontSize: 14}}/>
                }
            </div>
        </div>
    )
}

export default Candidate