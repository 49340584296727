import React from 'react'
import { Dialog, DialogTitle, List, ListItem, makeStyles, IconButton } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

const styles = makeStyles({
    dialogRoot: {
        height: '100%',
        overflow: 'hidden', 
        display: 'flex', 
        flexDirection: 'column', 
        position: 'relative',
        '& ul': {
            overflow: 'auto', 
            height: '100%', 
        }
    },
    resultsList: {
        height: '100%',
        overflow: 'auto',
        flexGrow: 1
    },
    candidateRoot: {
        position: 'relative',
        display: 'flex', 
        flexDirection: 'row',
        height: 120,
        padding: 10, 
    },
    header: {
        display: 'flex', 
        justifyContent: 'space-between', 
        padding: 10, 
        '& .dialogTitle': {
            fontSize: 24
        }

    },
    resultsInfo: {
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
        '& #pollsReported': {
            fontSize: 12,
            fontWeight: 'bolder'
        },
        '& #totalVotes': {
            fontSize: 10,
            color: 'darkslategrey'
        }
    },
    imageDiv: {
        height: '100%',
        position: 'relative',
        // marginBottom: 25,
        minWidth: 50,
        width: props=>props.screensize < 700 ? 50 : 70,
        borderRadius: '8px',
        overflow: 'hidden',
        
        '& img': {
            width: '100%',
            transition: 'width 500ms'
        }
    },
    candidateLeftDiv: {
        flex: 3, 
        display: 'flex', 
        height: '100%', 
        flexDirection: 'column',
        alignSelf: 'flex-start',
        padding: '10px 0 10px 10px',

        '& #candidateIncumbent': {
            fontSize: 13,
            fontWeight: 'lighter'
        }
    },
    candidateName: {
        fontWeight: 'bolder',
        
    }, 
    candidateRightDiv: {
        flex: 2, 
        justifySelf: 'flex-end',
        display: 'flex', 
        flexDirection: 'column',
        height: '80%',
        justifyContent: 'space-between',
        '& #candidateElected': {
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'flex-end',
            fontSize: props=>props.screensize < 700 ? 12 : 14,
        }
    },
    votesDiv: {
        textAlign: 'right',
        display: 'flex', 
        justifyContent: 'space-between', 
        flexDirection: 'column',
        flexGrow: 1,
        '& #votesPercent': {
            fontSize: 22,
            fontWeight: 'bolder'
        },
        '& #votesTotal': {
            fontSize: props=>props.screensize < 700 ? 12 : 14,
            fontWeight: 'lighter'
        }
    },
    polls: {
        fontSize: 16,
        fontWeight: 100
    }
})

export default function SimpleDialog({results, handleClose, getColor, open, handleLoad, showHeads}) {
    const classes = styles()
  
    const getHeadshot = (candidate) => {
        try {
            // console.log('here',candidate.cachedHeadFilename.split('\\')[3].split('.png')[0])
            return candidate.cachedHeadFilename.split('\\')[3].split('.png')[0].replace('_PP_', '_PPC_')
        } catch(e) {
            console.log('error loading headshot', candidate.cachedHeadFilename)
            return 'headshot'
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} >
            <div className={classes.dialogRoot}>
            <div  className={classes.header}>
                <div>
                    <div className="dialogTitle">
                        {results.name}
                    </div>
                    <div className={classes.polls}>
                        {results.pollsReported}/{results.pollsTotal} polls reporting
                    </div>
                </div>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon   />
                </IconButton>
            </div>
            <div className={classes.resultsList}>
            <List >
                {results.results.map(candidate=>{
                    let partyData = getColor(candidate)
                    return (
                        <ListItem className={classes.candidateRoot}>
                             <div style={{backgroundColor: partyData.color}} className={classes.imageDiv}>
                             <img style={{width: showHeads ? '100%' : '0px'}} onLoad={handleLoad} onError={(e) => { e.target.onError = null; e.target.src =`headshots/headshot.jpg`}} src={`headshots/${getHeadshot(candidate)}.jpg`}/>

                            </div>
                            <div className={classes.candidateLeftDiv}>
                                <div className={classes.candidateDiv}>
                                    <div className={classes.candidateName}>
                                        <div id="candidateLast">{candidate.name}</div>
                                        {/* <div id="candidateFirst">{candidate.firstName}</div>
                                        <div id="candidateLast" >{candidate.lastName}</div> */}
                                        
                                    </div>
                                    <div id="candidateIncumbent">{candidate.isIncumbent && 'Incumbent'}</div>
                                </div>
                                <div id="partyCode" style={{fontFamily: 'proxima-nova-bold',color: partyData.color}}>{candidate.partyCode}</div>

                            </div>
                            <div className={classes.candidateRightDiv}>
                                <div className={classes.votesDiv}>
                                    <div id='votesPercent'>{candidate.percent}%</div>
                                    <div id='votesTotal'>{candidate.votes.toLocaleString('en')} total votes</div>
                                </div>
                                {candidate.isElected ? <div id="candidateElected">
                                    <CheckCircleIcon style={{paddingRight: 5, fontSize: 14, color: 'green'}} /> Elected
                                </div>
                                :
                                <div style={{fontSize: 14}}/>
                                }
                            </div>
                        </ListItem>
                    )
                })}
            </List>
            </div>
            </div>
        </Dialog>
    )
}
