import React, {useEffect, useContext} from 'react'

import {makeStyles} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import { MapContext } from './mapContext';

const styles = makeStyles({
    geoTooltip: {
        fontFamily: 'proxima-nova',
        display: 'flex',
        flexDirection: 'column',
        transition: '1s', 
        width: 200,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        zIndex: 1200, 
        position: 'relative'
    },
    x: {
        position: 'absolute', 
        right: 0, 
        top: -5, 
        '& svg': {
            fontSize: '1rem',
            color: '#9E9E9E'
        }
    },
    tooltipName: {
        fontSize: 20,
        fontFamily: 'proxima-nova-bold',
        
    },
    tooltipPolls: {
        fontSize: 14,
        color: 'grey',
        paddingBottom: 10
    },
    tooltipWinner:{
        backgroundColor: props=>props.color,
        color: 'white',
        padding: 5, 
        '& #tooltipPartyCode': {
            fontSize: 18
        },
        '& #tooltipWinnerName': {
            fontSize: 20,
            fontFamily: 'proxima-nova-semibold',
            textShadow: '1px 1px 0.5px black',
            letterSpacing: 1
            // whiteSpace: 'pre-wrap',

        },
        '& #tooltipWinningBy' : {
            fontSize: 13
        }
    }
})


const Tooltip = (props) => {

    const classes = styles(props)    
    const {state, dispatch} = useContext(MapContext)

    const getWinningBy = () => {
        try {
            let winner = props.results.results[0]
            let runnerup = props.results.results[1]
            if (winner.votes > runnerup.votes) {
                return `Leading by ${(winner.votes - runnerup.votes).toLocaleString('en')} votes`
            }
        } catch (e) {
            // return ''
        }
    }

    return (
        <div id="resultsTooltip" className={classes.geoTooltip}>
            {props.showX && <div className={classes.x} onClick={()=>dispatch({type: 'CLOSE_POPUP'})}><Close /></div>}
            <div id="ridingNameTooltip" className={classes.tooltipName}>{props.riding}</div>
            {props.results && 
            <>
            <div id="tooltipPartycode" className={classes.tooltipPolls}>{props.results.pollsReported}/{props.results.pollsTotal} polls reported</div>
            {props.results.results[0].votes ? <div className={classes.tooltipWinner} style={{backgroundColor: props.color}}>
                <div id="tooltipPartycode">{props.results.results[0].partyCode}</div>
                <div id="tooltipWinnerName">{props.results.results[0].name}</div>
                <div id="tooltipWinningBy">{getWinningBy()}</div>
            </div>
            : <></>}
            </>}
        </div>
    )
}

export default Tooltip