import React, {useEffect, createContext, useReducer, useState} from 'react'

import {useParams} from 'react-router-dom'
import * as topojson from "topojson-client";

export const MapContext = createContext(null);


// const geoJSON = require('./ED_Boundaries_simplified.json')
const ProvinceData = require('./ProvinceData.json');

const initialState = {
    test: true,
    zoomCenter: {
        zoom: 2, 
        center: [61,-96.5]
    },
    map: null,
    geoJSON: {},
    selectedRiding: '',
    selectedProvince: '',
    provinceZoom: false,
    menu: 'provinces',
    ridingResults: null,
    provinceData: ProvinceData
}

function mapReducer(state, action) {
    switch(action.type) {
        case 'RESET': 
            return {
                ...state, 
                selectedRiding: null, 
                selectedProvince: null,
                provinceZoom: false,
                menu: 'provinces', 
                showPopup: false
            }
        case 'SET_MAP': 
            return {
                ...state, 
                map: action.map
            }
        case 'CLICK_PROVINCE': 
            return {
                ...state, 
                selectedProvince: action.province, 
                provinceZoom: true,
                menu: action.menu
            }
        case 'CLICK_RIDING': 
            return {
                ...state, 
                selectedRiding: action.riding,
                selectedProvince: action.province,
                provinceZoom: false,
                menu: 'ridings', 
                showPopup: true, 
                ridingResults: action.ridingResults
            }
        case 'CLOSE_POPUP' :
            return {
                ...state,
                showPopup: false
            }
        case 'SET_MENU': 
            return {
                ...state, 
                menu: action.menu
            }
        case 'SET_PROVINCE':
            console.log('set province')
            return {
                ...state, 
                selectedProvince: action.province
            }
    }
}


const MapContextProvider = (props) => {
    const [state, dispatch] = useReducer(mapReducer, initialState)
    const [geoJSON, setgeoJSON] = useState(null)
    let {year, riding} =useParams();
    
    const convertToGeoJSON = (jsonData) => {
        if (jsonData.type === "Topology") {
            for (let key in jsonData.objects) {
              let geojson = topojson.feature(jsonData, jsonData.objects[key]);
              return geojson
            }
          } else {
            return jsonData;
            
          }
    }

    useEffect(()=> {
        fetch(`data/topoJSON.json`)
            .then(res=>res.json())
            .then(json=>{
                console.log(json)
                let geoJSON = convertToGeoJSON(json)
                setgeoJSON(geoJSON)
                if (riding) {
                    const findRiding = geoJSON.features.find(geo=>{
                        return geo.properties.FEDENAME_.toLowerCase() === riding.toLowerCase()
                    })
                    if (riding.toLowerCase() === "nunavut" || riding.toLowerCase() === "northwest territories" || riding.toLowerCase() === "yukon") {
                        dispatch({type: 'CLICK_RIDING', riding: findRiding.properties.FEDENAME_, province: "Territories"})
                    } else {
                        dispatch({type: 'CLICK_RIDING', riding: findRiding.properties.FEDENAME_, province: findRiding.properties.PROVNAME_})
                    }  
                }
            })
        return ()=>{
            console.log('unmount map')
        }
    },[])
    return (
        <MapContext.Provider value={{state, dispatch, geoJSON}}>
            {props.children}
        </MapContext.Provider>
    )
}

export default MapContextProvider