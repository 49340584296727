import React, {useContext, useState, useEffect} from 'react'

import {DataContext} from '../../dataContext'

import Candidate from './Candidate'

const Results = (props) => {
    const {data} = useContext(DataContext)
    const [load, toggleLoad] = useState(false)

    let candidates = 0;

    const getColor = (candidate) => {
        const partyCode = candidate.partyCode
        let partyInfo = data.party.partyResults.filter(party => {
            return party.nameShort === partyCode
        })[0]
        if (partyInfo) {
            return partyInfo
        } else 
        return {
            color: "#C0C0C0",
            name: "Other",
            nameShort: "OTH"
        }
            
        
    }
    const handleLoad = () => {
        candidates ++;
        console.log('load', candidates === props.results.results.length);
        if (candidates === props.results.results.length) {
            toggleLoad(true)
        }
    }

    return <div key={props.id}>
        {/* {console.log('here')} */}
        {props.results.results.map((candidate,i)=>{
            const partyData = getColor(candidate)
            return (
                <div key={candidate.id}>
                    <Candidate showHeads={load} handleLoad={handleLoad} id={i} candidate={candidate} partyData={partyData}/>
                </div>
            )
        })}
    </div>
}

export default Results