import React, {useContext, useState, useEffect} from 'react'

import {makeStyles} from '@material-ui/core/styles';

import {DataContext} from '../../dataContext'

import Declaration from './Declaration'


const styles = makeStyles({
	main: {
		// fontFamily: 'Roboto',
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		padding: '10px 10px',
		backgroundColor: '#ededf0'
	},
	titleRow: {
		display: 'flex', 
		padding: '0px 5px',
		justifyContent: 'space-between', 
		alignItems: 'center'
	},
	title: props=> ({
		fontSize: 18,
		fontWeight: 'bold',
		paddingRight: 20
	}),
	majorityMeter: props => ({
		display: 'flex',
		alignItems: 'center',
		textAlign: 'right',
		fontSize: 12,
	}),
	seatMap: {
		display: 'flex',
		height: 50,
		maxHeight: 100,
		flexDirection: 'column-reverse',
		flexWrap: 'wrap',
	},
	partyMap: {
		display: 'flex',
		justifyContent: 'flex-start',
		padding: '5px 0px'
	},
	seat: {
        height: 5, 
        // minWidth: '4%',
        margin: '0.1%',
        // backgroundColor:  (props)=>props.color,
        flexShrink: 1,
		fontSize: 16,
		paddingBottom: 5
	},
	partyContainer: {
        width: '20%',
		paddingTop: 5,
		display: 'flex',
        alignItems: 'center',
		fontWeight: 'bold',
		flexDirection: 'column',
        fontSize: 12,
        letterSpacing: 2,
        margin: 1,
        borderRadius: 5,
		color: 'white',
		justifyContent: 'space-between'
    },
    popVote:{
        padding: '5px 0px',
        fontWeight: 'bold',
        fontSize: 12
	},
	update: {
		fontSize: 10
	}
})


export default function SeatApp() {

	const {data} = useContext(DataContext)
	const [seats, setSeats] = useState([])
	const [parties, setParties] = useState([])
	const [declarationText, setDeclaration] = useState('')
	
    const seatInfo = {
        total: 338,
        majority: 170
    }

    const classes = styles()

	const date = new Date()

	const Seat = (props) => {
		
		return (
			<div key={`${props.party}-${props.el}`} className={classes.seat} style={{backgroundColor: props.color}} />
		);
	}

	const Party = (props) => {
		const {party} = props
		return (
			<div key={party.id} className={classes.partyContainer} style={{backgroundColor: party.color}}>
				<div>{party.partyName}</div>
				<div className={classes.popVote}> {party.seats} </div>
			</div>
		);
	}

    useEffect(()=> {
        if (data.party) {
			let total = 0
			setSeats([])
			setParties([])
            data.party.partyResults.map((party, i)=>{
				for (let j=0; j<party.seats; j++) {
					total++
                    setSeats( oldArray=> [ ...oldArray, { party: party.nameShort,  id: j, color: party.color }])
				}
				setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, color: party.color,votesPercent: party.votesPercent,votesTotal: party.votes}])
				
			})
			if (total < data.config.total) {
				for (let k=0; k<data.config.total-total; k++) {
					setSeats( oldArray=> [ ...oldArray, { party: 'none',  id: k, color: '#e0e0e0' }])
				}
			}
		}
		if (data.declaration) {
			if (data.declaration.overallResult.resultText) {
				let text = data.declaration.overallResult.partyName + ' ' + data.declaration.overallResult.resultText;
				setDeclaration(text)	
			} else {
				setDeclaration('')
			}
		}
    }, [data])

    return (
        <div>
            {data.party && 
			<div className={classes.main}>
				<div className={classes.titleRow}>
					<div className={classes.title}>{data.config.title}</div>
					{/* {<div className={classes.majorityMeter}>{seatInfo.majority} seats needed for majority 
						<MajorityMeter seatTotal={seatInfo.total} majority={seatInfo.majority} majorityPercent={(seatInfo.majority/seatInfo.total)*100} data={data.party}/>
					</div>} */}
				{declarationText && 
					<Declaration declarationText={declarationText} />
				}
				</div>
				<div className={classes.seatMap}>
					{seats.map((seat, i)=>{
							return <Seat key={seat.party+'-'+i} party={seat.party} el={seat.id} color={seat.color}/>
						})
					}
				</div>
				<div className={classes.partyMap}>
					{parties.map((party,i)=>{
						if (i <=data.party.partyResults.length-1 && i > 5) {
							if (!party.seats) {
								if (party.isMainParty && party.nameShort !== 'IND') {
									return <Party key={party.id} party={party}/>
								}
								return;
							}
						}
						return <Party key={party.id} party={party}/>
					})}
				</div>
				{/* <div className={classes.update}>Last updated: {date && date.toString()}</div> */}
		    </div>}
        </div>
    )
}