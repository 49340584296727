import React, {useContext, useState, useEffect} from 'react'

import {makeStyles} from '@material-ui/core/styles';

import {DataContext} from '../../dataContext'

import MajorityMeter from './MajorityMeter'
import Declaration from './Declaration'
import { Button, ButtonGroup, FormControlLabel, Switch } from '@material-ui/core';

const styles = makeStyles({
	seatApp: {
		height: 250,
		position: "relative",
		backgroundColor: '#ededf0',
		padding: 10
	},
	main: {
		// fontFamily: 'Roboto',
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		height: '100%'
	},
	left: {
		flex: 4
	},
	titleRow: {
		display: 'flex', 
		flex: '1',
		gap: 20,
		// padding: '0px 5px',
		paddingBottom: 5,
		justifyContent: 'space-between', 
		'& button': {
			fontFamily: 'proxima-nova-bold !important', 
		},
		'& #contained': {
			color: '#3f51b5', 
			backgroundColor: '#3f51b529', 
		}
	},
	title: props=> ({
		fontSize: props.small ? 18  : 24,
		fontWeight: 'bold',
		// paddingRight: 20, 
		display: 'flex', 
		gap: '50px',
		alignItems: 'center', 
		justifyContent: 'space-between', 
		'& label': {
			border: '1px solid grey',
			borderRadius: 5,
		},
		'& .MuiFormControlLabel-label': {
			fontFamily: 'proxima-nova !important', 
			fontSize: 14,
			paddingRight: 10
		}
	}),
	majorityMeter: props => ({
		display: 'flex',
		alignItems: 'center',
		textAlign: 'right',
		fontSize: 12,
		flex: 1
	}),
	declarationText: {
		paddingTop: 5,
		'& #party' : {
			fontWeight: 'bold'
		},
		'& #majority': {
			fontSize: 14
		}
	},
	seatContainer: {
		flex: '3',
		position: 'relative',
		overflow: 'hidden',
		flexGrow: 3
	},
	seatMap: {
		display: 'flex',
		flexDirection: 'column-reverse',
		flexWrap: 'wrap',
		height: '100%',
		position: 'relative'
	},
	partyMap: {
		display: 'flex',
		flex: '2',
		maxHeight: 60,
		justifyContent: 'flex-start',
		padding: '3px 0px',
		overflowX: 'auto'
	},
	seat: {
		height: '15%',
        margin: '0.1%',
        flexShrink: 1,
		paddingBottom: 5
	},
	partyContainer: {
		flex: 1,
        width: '20%',
		paddingTop: 5,
		display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 14,
        letterSpacing: 2,
        padding: '5px 5px',
        margin: 1,
        borderRadius: 5,
        color: 'white',
        justifyItems: 'center',
		justifyContent: 'space-between',
		'& #partySeatsTotal': {
			fontSize: 18
		}
    },
    popVote:{
        padding: '5px 0px',
        fontWeight: 'bold',
        fontSize: 20
	},
	update: {
		// flex:1,
		fontSize: 10
	}
})


export default function SeatApp() {

	const {data, setYear} = useContext(DataContext)
	const [seats, setSeats] = useState([])
	const [parties, setParties] = useState([])
	const [declarationText, setDeclaration] = useState({
		text: '', 
		elected: false
	})
    const classes = styles()

	const date = new Date()

	const Seat = (props) => {
		
		return (
			<div key={`${props.party}-${props.el}`} className={classes.seat} style={{backgroundColor: props.color}} />
		);
	}

	const Party = (props) => {
		const {party} = props
		return (
			<div key={party.id} id="partyCard" className={classes.partyContainer} style={{backgroundColor: party.color}}>
				<div>{party.partyName}</div>
				<div id="popVote" className={classes.popVote}> {party.seats} </div>
			</div>
		);
	}

    useEffect(()=> {
		console.log('detect change', data.currentYear)
        if (data.party) {
			let total = 0
			setSeats([])
			setParties([])
			if (data.currentYear) {
				data.party.partyResults.map((party, i)=>{
					for (let j=0; j<party.seats; j++) {
						total++
						setSeats( oldArray=> [ ...oldArray, { party: party.nameShort,  id: j, color: party.color }])
					}
					if (i <=data.party.partyResults.length-1 && i > 5) {
						if (!party.seats) {
							if (party.isMainParty && party.nameShort !== 'IND') {
								setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, color: party.color,votesPercent: party.votesPercent,votesTotal: party.votes}])
							}
							return;
						}
					}
					setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, color: party.color,votesPercent: party.votesPercent,votesTotal: party.votes}])
					
				})
				if (total < data.config.total) {
					for (let k=0; k<data.config.total-total; k++) {
						setSeats( oldArray=> [ ...oldArray, { party: 'none',  id: k, color: '#e0e0e0' }])
					}
				}
			} else {
				data.prev.partyResults.map((party, i)=>{
					for (let j=0; j<party.seats; j++) {
						total++
						setSeats( oldArray=> [ ...oldArray, { party: party.nameShort,  id: j, color: party.color }])
					}
					if (i <=data.prev.partyResults.length-1 && i >4) {
						if (!party.seats) {
							if (party.isMainParty && party.nameShort !== 'IND') {
								setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, color: party.color,votesPercent: party.votesPercent,votesTotal: party.votes}])
							}
							return;
						}
					}
					setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, color: party.color,votesPercent: party.votesPercent,votesTotal: party.votes}])
					
				})
				if (total < data.config.total) {
					for (let k=0; k<data.config.total-total; k++) {
						setSeats( oldArray=> [ ...oldArray, { party: 'none',  id: k, color: '#e0e0e0' }])
					}
				}
			}
            
		}

		let winner;
		let leading = false;
		if (data.config) {
			if (data.currentYear) {
				if (data.declaration.overallResult.resultText) {
					let text = data.declaration.overallResult.partyName + ' ' + data.declaration.overallResult.resultText;
					setDeclaration({elected: true, text: text})	
				} else {
					if (data.party.partyResults[0].seats > data.party.partyResults[1].seats) {
						winner = data.party.partyResults[0].name
						leading = true
						setDeclaration({elected: false, text: winner + ' leading - ' + data.config.majority + ' seats needed for majority'})
					} else {
						setDeclaration({ elected: false, text: data.config.majority + ' seats needed for majority'})
					}
				}
			} else {
				if (data.prevOverall.overallResult.resultText) {
					setDeclaration({elected: true, text: data.prevOverall.overallResult.partyName + ' ' + data.prevOverall.overallResult.resultText})
				}
			}
	
		}
	}, [data])
	
	const DeclarationText = () => {
		let winner;
		let leading = false;
		let declaration;
        if (data.party.partyResults[0].seats > data.party.partyResults[1].seats) {
			winner = data.party.partyResults[0].name
			leading = true
        } else {

		}

		if (data.declaration.overallResult.resultText) {
			declaration = 'elected ' + data.declaration.overallResult.resultText
		}

		return (
            <div id="declarationText" className={classes.declarationText}>
                {leading && <>
					<span id="party">{winner}s</span>
					<span id="helpertext"> leading </span> 
					{declaration &&
						<span>and elected</span>
					}
					<span> - </span>
				</>
				}
				<span id="majority">{data.config.majority} seats needed for majority</span>
            </div>
        )
    }

    return (
        <div className={classes.seatApp}>
			{(data.party && seats && parties) && 
			<div className={classes.main}>
				<div className={classes.titleRow} id="widgetTitleRow">
					<div className={classes.left}>
						<div id="widgetTitle" className={classes.title}><span>{data.config.title}</span>
						<ButtonGroup>
							<Button id={data.currentYear ? "": "contained"} onClick={()=>setYear(false)}>{data.config.prevElection}</Button>
							<Button id={!data.currentYear ? "": "contained"} onClick={()=>setYear(true)}>{data.config.currentElection}</Button>
						</ButtonGroup>
						{/* <FormControlLabel
							control={
							<Switch
								checked={!data.currentYear}
								onChange={setYear}
								color="primary"
							/>
							}
							label={`${data.config.prevElection} Results`}
						/> */}
						
						{data.prev === 'current' && <Button variant={data.currentYear ? 'default' : 'contained'} color={data.currentYear ? 'default' : 'primary'} onClick={setYear}>{data.config.prevElection} Results</Button>}</div>
						<Declaration elected={declarationText.elected} declarationText={declarationText}/>
					</div>
					{<div id="majorityMeter" className={classes.majorityMeter}>
						<MajorityMeter seatTotal={data.config.total} majority={data.config.majority} majorityPercent={(data.config.majority/data.config.total)*100} data={data.currentYear ? data.party : data.prev}/>
					</div>}
				</div>
				
				<div id="seatContainer" className={classes.seatContainer}>
					<div className={classes.seatMap}>
						{seats.map((seat, i)=>{
								return <Seat key={seat.party+'-'+i} party={seat.party} el={seat.id} color={seat.color}/>
							})
						}
					</div>
				</div>
				<div className={classes.partyMap}>
					{parties.map(party=>{
						return <Party key={party.id} party={party}/>
					})}
				</div>
				<div className={classes.update}>Last updated: {date && date.toString()}</div>
		    </div>}
        </div>
    )
}