import React, { useState, useEffect, useContext } from 'react';
import {FormControl, InputLabel, makeStyles, MenuItem, Select} from '@material-ui/core'

import {DataContext} from '../../dataContext'

import Loading from './Loading'

import { useParams} from 'react-router-dom'
import ResultsBox from './ResultsBox';
import SimpleDialog from './SimpleDialog';

const styles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        flexDirection: 'column',
    },
    title: {
        background: '#9e9e9e',
        padding: 10, 
        fontSize: 24,
        color: 'white'
    },
    selectContainer: {
        width: 200,
        padding: 20
    },
    formControl: {
        width: '100%',
    }, 
    main: {
        overflow: 'auto', 
        flexGrow: 1
    },
    resultsContainer: {
        overflowY: 'auto',
        overflowX: 'hidden'
        
    }
})

function FilteredResultsApp() {
    const {data} = useContext(DataContext)
    const [filter, setFilter] = useState(null)
    const [results, setResults] = useState(null)
    const [selectedRidingResults, setSelectedRiding] = useState('')
    const [show, toggleShow] = useState(false)
    const [loadHeadshots, toggleLoadHeadshots] = useState(false)
    // const [loading, setLoading] = useState(true)
    const classes = styles()
    let {year, loadfilter} = useParams()

    let candidates = 0

    const handleChange = (value) => {
        setFilter(value);
        // setLoading()
    }

    const handleSelectRiding = (ridingResults) => {
        setSelectedRiding(ridingResults)
        toggleShow(true)
    }

    const getData = (list) => {
        console.log('getData')
        let requests = list.map(el=>fetch(`data/${year}/filtered/${el.filename}`).then(res=>res.json()));
        Promise.all(requests).then(res=>{
            res.forEach((el,i)=>{
                setResults(state=>({...state, [`${list[i].label}`]: el}))
            })
        })
    }

    useEffect(()=> {
        try {
            if (selectedRidingResults) {
                console.log('update omdal', selectedRidingResults)
                let riding = selectedRidingResults.name
                let newData = data.full.data.find(el=>el.name===riding)
                setSelectedRiding(newData)
            }
    
        } catch (e) {
            console.log('error updating filtered results modal')
        }
    }, [data])

    useEffect(()=> {
        console.log('get Results', data.config)
        let interval;
        if (data.config) {
            getData(data.config.filteredResults)

            setInterval(()=> {
                getData(data.config.filteredResults)
            }, data.config.timer)

            if (loadfilter) {
                const findFilter = data.config.filteredResults.find(el=>el.urlSlug.toLowerCase() === loadfilter.toLowerCase())
                console.log('test?',findFilter)
                if (findFilter) {
                    console.log(findFilter.label)
                    handleChange(findFilter.label)
                }
            }
        }
        return () => {
            clearInterval(interval)
        }
    }, [data.config])

    const getColor = (candidate) => {
        const partyCode = candidate.partyCode
        let partyInfo = data.party.partyResults.find(party => {
            return party.nameShort === partyCode
        })
        if (partyInfo) {
            return partyInfo
        } else 
        return {
            color: "#C0C0C0",
            name: "Other",
            nameShort: "OTH"
        }
    }
    
    const GetResults = () => {
        console.log('here',results[filter])
        if (results[filter]) {
            return (
                <ResultsBox handleSelectRiding={handleSelectRiding} results={results[filter] && results[filter]} filter={filter}/>
            )
        } else {
            return (
                <p style={{paddingLeft: 20, }}>Select Filter to view Results</p>
            )
        }
        
    }

    const handleLoad = () => {
        if (selectedRidingResults) {
            candidates ++;
            console.log('load', selectedRidingResults, candidates === selectedRidingResults.length);
            if (candidates === selectedRidingResults.results.length) {
                toggleLoadHeadshots(true)
            }
    
        }
    }

    const handleClose = () => {
        toggleShow(false)
        toggleLoadHeadshots(false)
        candidates = 0
    }
    

    if (!data.config) {
        return (
            <Loading />
        )
    } else {
        return (
            <div className={classes.root}>
                {/* <div className={classes.title}>Filtered Results</div> */}
    
                {data.config.filteredResults ? 
                <>
                    <div className={classes.selectContainer}>
                            <FormControl className={classes.formControl}>
                            <InputLabel shrink={filter ? true : false}>Filter</InputLabel>
                            <Select 
                                value={filter} 

                                onChange={(e) => handleChange(e.target.value)}>
                                {data.config.filteredResults.map(filter=>{
                                    return (
                                        <MenuItem value={filter.label}>
                                            {filter.label}
                                        </MenuItem>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                    </div>  
                    <div className={classes.resultsContainer}>
                        {results && <GetResults/>}
                        {selectedRidingResults && <SimpleDialog getColor={getColor} results={selectedRidingResults} open={show} showHeads={loadHeadshots} handleLoad={handleLoad} handleClose={handleClose}/>}
                    </div>
                </>
                :
                <div>
                    Include a list of filters for election year
                </div>}
            </div>
        )
    }

}
export default FilteredResultsApp
